<!-- 第一页 -->
<template>
<div class="app-box outBox" v-if="!isphone">
  <!-- 外层容器 -->
  <div style="width:100%;height:100%" >
    <!-- 底部的装饰 -->
    <div class="bottomDecoration">
      <img :src="bottomDecoration" alt="" style="width:100%;height:100%">
    </div>
    <!-- 登录的盒子 -->
    <div class="loginBox">
        <!-- logo-左 -->
        <div class="logoBox">
            <div class="logo">
                <img :src="pic" alt="" style="width:100%;height:100%">
            </div>
        </div>
        <!-- -右 -->
        <div class="contentBox">
            <div class="login">
                <!-- title -->
                <div class="loginTitle">{{title}}</div>
                <!-- content -->
                <div class="qrcode">
                    <img :src="picQrcode" alt="">
                </div>
                <div><span class="loginTip">请使用微信扫一扫登录</span></div>
            </div>
        </div>
    </div>
    <!-- <gaodeMap @changeLocation="changeLocation"></gaodeMap> -->
  </div>
</div>
<div v-else>只能在电脑上访问哦</div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
// import AMap from 'AMap'
import apis from "../../api/request.js";
import pic from '../../../static/images/logo.png'
import bottomPiv from '../../../static/images/bottom.png'
// import gaodeMap from './gaodeMap'
// var _this = this
export default {
//import引入的组件需要注入到对象中才能使用
components: {
},
data() {
//这里存放数据
return {
    picQrcode: '',
    pollingST : null,
    isphone:false,
    center: {lng: 0, lat: 0},  //经纬度
    zoom: 3,
    subLoading:false,
    passType:"text",
    title:"闲画艺术课堂",
    pic:pic,
    bottomDecoration:bottomPiv,
    loginForm:{
        loginName:"请输入用户名",
        loginPassword:"请输入密码",
        longitude:"121.47010842679067",//经度
        latitude:"31.23901439938865",//纬度

    }
};
},
//监听属性 类似于data概念
computed: {
  
},
//监控data中的数据变化
watch: {
    // loginForm:{
    //     handler(val){
    //         console.log(val);
    //     }
    // }
},
//方法集合
methods: {
    getFocus(){
        if(this.loginForm.loginName === "请输入用户名"){
            this.loginForm.loginName = ""
        }
    },
    getsFocus(){
        if(this.loginForm.loginPassword === "请输入密码"){
            this.loginForm.loginPassword = ""
            this.passType = "password"
        }
    },
    blurFn(){
        if(this.loginForm.loginName === ""){
            this.loginForm.loginName = "请输入用户名"
        }
    },
    blurFna(){
        if(this.loginForm.loginPassword === ""){
            this.loginForm.loginPassword = "请输入密码"
            this.passType = "text"
        }
    },
    submit(){//登陆事件
        if(this.loginForm.loginName === "请输入用户名" || this.loginForm.loginPassword === "请输入密码"){
            this.$message.error("用户名或密码不能为空哦")
            return
        }    
        this.subLoading = true
        let args = {
            mobile:this.loginForm.loginName,
            password:this.loginForm.loginPassword, 
            latitude:this.loginForm.latitude,
            longitude:this.loginForm.longitude
        }
		if (this.loginForm.latitude === '' || this.loginForm.longitude === '') {
			args.latitude = '31.23901439938865'
			args.longitude = '121.47010842679067'
		}
        // console.log(apis);
        // console.log("126", args);
        // return
        this.$post(apis.getLogin, args).then(res=>{
            // console.log(res);
            this.afterLogin(res)//登录事件
            
        }).catch(err=>{
            console.log(err);
            this.subLoading = false
        })
        // this.$router.push("/home")
    },
    afterLogin(res) {//登录存储信息
        this.subLoading = false
        if(res.code === 0){
            // console.log("138", res);
            // console.log("141", res.data.token);
            let info = res.data.user
            // console.log('143', res.data.user);
            localStorage.setItem('userName', info.real_name);
            localStorage.setItem('avatar', info.avatar);//头像
            localStorage.setItem('userId', info.id);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('userMobile', info.mobile);
            localStorage.setItem('schoolId', info.school_id);
            localStorage.setItem('schoolType', res.data.organization.school_type)
            // 1、幼儿园 2、小学
            // localStorage.setItem('ageSection', 2);
            if(res.data.organization.school_type == 1){
                localStorage.setItem('ageSection', 3);
            }
            if(res.data.organization.school_type == 2){
                localStorage.setItem('ageSection', 1);
            }
            // headers
            // this.$axios.defaults.headers.common['Authorization'] = res.data.token;
            this.$router.push("/home")
        }
        if(res.code !== 0){
            this.$message.error(res.message)
            return
        }
    },
    qrcode() {
        this.$get(apis.qrcode).then(res=>{
            this.picQrcode = 'data:image/png;base64, ' +  res.data.qrcode
            this.checkQrLoginState(res.data.uuid)
        }).catch(err=>{
            console.log(err);
        })
    },
    refreshQrCode() {
        this.qrcode()
    },
    checkQrLoginState(uuid) {
        this.$post(apis.checkQrLoginState, {'uuid': uuid}).then(res=>{
            // 0:已成功， 1：未登录
            if (res.code == 0) {
                this.afterLogin(res)//登录事件
            } else if (res.code == 1) {
                this.pollingST = setTimeout(() => {
                    clearTimeout(this.pollingST)
                    this.checkQrLoginState(uuid);
                }, 3000)
            }
            
        }).catch(err=>{
            console.log(err);
        })
    }
},
beforeCreate() {}, //生命周期 - 创建之前
//生命周期 - 创建完成（可以访问当前this实例）
created() {

},
beforeMount() {}, //生命周期 - 挂载之前
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    this.qrcode();
    var mapObj = new AMap.Map('iCenter');
	var _this = this
      mapObj.plugin('AMap.Geolocation', function () {
        let geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000,           // 超过10秒后停止定位，默认：无穷大
          maximumAge: 0,            // 定位结果缓存0毫秒，默认：0
          convert: true,            // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true,         // 显示定位按钮，默认：true
          buttonPosition: 'LB',     // 定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          showMarker: true,         // 定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true,         // 定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true,      // 定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy:true       // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        });
        mapObj.addControl(geolocation);
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, 'complete', onComplete); // 返回定位信息
        AMap.event.addListener(geolocation, 'error', onError);       // 返回定位出错信息
      });
      function onComplete(obj){
			const bd_lat_lon = []
			bd_lat_lon.push(obj.position.lat)
			bd_lat_lon.push(obj.position.lng)
			const a = 3.14159265358979324 * 3000.0 / 180.0;
			const x = bd_lat_lon[1], y = bd_lat_lon[0];
			const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * a);
			const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * a);
			bd_lat_lon[0] = z * Math.cos(theta) + 0.0065;
			bd_lat_lon[1] = z * Math.sin(theta) + 0.006;
			_this.loginForm.longitude = bd_lat_lon[0]
			_this.loginForm.latitude = bd_lat_lon[1]
			console.log('217', _this.loginForm)		
      }
      function onError(obj) {
        console.log('err' ,obj);
		_this.loginForm.longitude = ''
		_this.loginForm.latitude = ''
      }
    var ipad = navigator.userAgent.match(/(iPad).*OS\s([\d_]+)/)
    var isIphone = !ipad && navigator.userAgent.match(/(iPhone\sOS)\s([\d_]+)/)
    var isAndroid = navigator.userAgent.match(/(Android)\s+([\d.]+)/)
    if(isIphone || isAndroid){
        this.isphone = true
    }
},
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.outBox{
    position: relative;
    background: url(../../../static/images/bgc.png) no-repeat;
    background-size: cover;
}
.bottomDecoration{
  width: 1.6625rem;
  height:1.7625rem;
  /* background-color: #fff; */
  position: absolute;
  bottom:0;
  left:0;
}
.loginBox{
    width: 12.15rem;
    height: 6.0125rem;
    /* width: 60%; */
    /* height:30%; */
    position: absolute;
    left:50%;
    top:50%;
    /* transform: translate(-50%,-50%); */
    margin-left:-6.075rem;
    margin-top: -3.00625rem;
    background-color: #fff;
    box-shadow: 0px 6px 48px rgba(148, 148, 148, 0.13);
    border-radius: 8px;
}
.logoBox{
    width: 4.525rem;
    height: 6.0125rem;
    position: absolute;
    left:0;
}
.logo{
    width: 3.8rem;
    height: 1.475rem;
    position:absolute;
    top:50%;
    left:50%;
    margin-left: -1.9rem;
    margin-top:-0.7375rem;
}
.contentBox{
    width: 7.625rem;
    height: 6.0125rem;
    right:0;
    position: absolute;
}
.login{
    width: 5.1375rem;
    height: 4.5625rem;
    position: absolute;
    top:50%;
    left:50%;
    margin-left: -2.56875rem;
    margin-top:-2.28125rem;
}
.loginTitle{
    width:100%;
    height:0.925rem;
    font-weight: bold;
    font-size: 0.4875rem;
}
.btn{
    width:100%;
    height:100%;
    font-size: 0.2rem;
}
.loginName{
    width:100%;
    height:0.64rem;
    font-size:0.2125rem;
    margin-bottom:0.5rem;
    border:0px;
    outline: none;
    border-bottom:2px solid black;
}
.qrcode {
    margin: 0 auto;
}
.qrcode img {
    display: block;
    width: 70%;
    margin: 0 auto;
}
.loginTip {
    font-size: 20px;
}
</style>